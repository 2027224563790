import { Route, Routes, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Main from "./pages/Main";
import MainLogged from "./pages/MainLogged";
import RequireAuth from "./pages/RequireAuth";
import UserSettings from "./pages/UserSettings";
import FormContainer from "./pages/FormContainer/FormContainer";
import SignInForm from "./components/Forms/Signin";
import SignUpForm from "./components/Forms/Signup";
import SignRecoveryForm from "./components/Forms/SignRecovery";
import SignValidateForm from "./components/Forms/SignValidate";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCategories } from "./redux/slices/category";
import Panaderia from "./components/Categories/Panaderia";
import Seminarios from "./components/Categories/Seminarios";
import Experiencia from "./components/Categories/Experiencia";
import Test from "./components/Test";
import SubCategoryData from "./components/SubCategoryData";
import ProductData from "./components/ProductData";
import CategoryContainer from "./components/CategoryContainer";
import Redes from "./components/Redes";
import BlogList from "./pages/BlogList";
import BlogPage from "./pages/BlogPage";
import { helpHttp } from "./helper/helpHttp";
import { toast } from "react-toastify";

const App = () => {
  const dispatch = useDispatch();
  const { get } = helpHttp();

  useEffect(() => {
    get("categorias/getCategorias")
      .then((data) => {
        dispatch(setCategories(data.datos));
      })
      .catch(() => toast.error("Ha ocurrido un error"));
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:BlogId" element={<BlogPage />} />
        <Route
          path="sign-in"
          element={
            <FormContainer>
              <SignInForm />
            </FormContainer>
          }
        />
        <Route
          path="sign-up"
          element={
            <FormContainer>
              <SignUpForm />
            </FormContainer>
          }
        />
        <Route
          path="sign-recovery"
          element={
            <FormContainer>
              <SignRecoveryForm />
            </FormContainer>
          }
        />
        <Route
          path="sign-validate"
          element={
            <FormContainer>
              <SignValidateForm />
            </FormContainer>
          }
        />
        <Route element={<RequireAuth />}>
          <Route path="user-settings" element={<UserSettings />} />
          <Route path="main" element={<MainLogged />} />
          <Route path="main/*" element={<Main />}>
            <Route
              path="Panader%C3%ADa%20y%20pasteler%C3%ADa"
              element={
                <CategoryContainer>
                  <Panaderia />
                </CategoryContainer>
              }
            />
            <Route
              path="Panader%C3%ADa%20y%20pasteler%C3%ADa/Aprende%20con%20nuestro%20portafolio"
              element={
                <CategoryContainer>
                  <SubCategoryData />
                </CategoryContainer>
              }
            />
            <Route
              path="Panader%C3%ADa%20y%20pasteler%C3%ADa/Nuestras%20recetas"
              element={
                <CategoryContainer>
                  <Redes />
                </CategoryContainer>
              }
            />
            <Route
              path="Panader%C3%ADa%20y%20pasteler%C3%ADa/Aprende%20con%20nuestro%20portafolio/:producto"
              element={
                <CategoryContainer>
                  <ProductData />
                </CategoryContainer>
              }
            />
            <Route
              path="Seminarios"
              element={
                <CategoryContainer showPresenciales={false}>
                  <Seminarios />
                </CategoryContainer>
              }
            />
            <Route
              path="Experiencia%20Fleischmann"
              element={
                <CategoryContainer>
                  <Experiencia />
                </CategoryContainer>
              }
            />
            <Route
              path="Experiencia%20Fleischmann/:subCategory"
              element={<Test />}
            />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
