import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import { Button, CardMedia } from "@mui/material";

export const Recurrents = ({ data = [] }) => {
  if (!data?.length) {
    return (
      <RecurrentsContainer>
        <CircularProgress />
      </RecurrentsContainer>
    );
  }

  return (
    <RecurrentsContainer>
      {data.map((item) => {
        return (
          <>
            <a className="recurrentContainer" href={item.ruta}>
              <Button sx={{ textTransform: "capitalize" }} variant="contained">
                {item.descripcion}
              </Button>
              <CardMedia
                className="card"
                component="img"
                image={item.imagen}
                alt={item.descripcion}
                sx={{ height: "100%" }}
              />
              <div class="content"></div>
            </a>
          </>
        );
      })}
    </RecurrentsContainer>
  );
};

const StyledContainer = styled.div`
  margin: 50px auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  max-width: 640px;
  @media (min-width: 1100px) {
    flex-direction: row;
    max-width: 1250px;
  }
`;

const RecurrentsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;
  padding-top: 10px;
  padding-bottom: 70px;
  flex-wrap: wrap;

  .recurrentContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .card {
    margin-top: 10px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 300px;
    height: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
    cursor: pointer;
  }

  .card .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000000;
    padding: 10px;
    transition: 0.5s;
    user-select: none;
    :hover {
      background-color: #00000040;
    }
  }
`;
