import styled from '@emotion/styled'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { images } from './images'
const Carousel = () => {
  const [activeImage, setActiveImage] = useState(0)

  useEffect(() => {
    const imgInterval = setInterval(() => {
      handleImage(activeImage + 1)
    }, 5000)

    return () => {
      clearInterval(imgInterval)
    }
  }, [activeImage])

  const handleImage = (index) => {
    setActiveImage(() =>
      index < 0 ? images.length - 1 : index > images.length - 1 ? 0 : index
    ) // Si se retocede desde la primera posicion te lleva al ultimo indice de las screenshots y viceversa
  }

  return (
    <>
      {images.length !== 0 && (
        <StyledCarousel>
          <StyledArrowsContainer>
            <StyledPButton onClick={() => handleImage(activeImage - 1)}>
              <ArrowBackIosRoundedIcon />
            </StyledPButton>
            <StyledNButton onClick={() => handleImage(activeImage + 1)}>
              <ArrowForwardIosRoundedIcon />
            </StyledNButton>
          </StyledArrowsContainer>

          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={images[activeImage].url}
              transition={{ duration: 0.2 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <StyledText>
                <StyledTitle
                  initial={{ x: 50 }}
                  animate={{ x: 0 }}
                  exit={{ x: 50 }}
                  key={images[activeImage].title}
                >
                  {images[activeImage].title}
                </StyledTitle>
                <StyledSubTitle
                  initial={{ y: 50 }}
                  animate={{ y: 0 }}
                  exit={{ y: 50 }}
                  key={images[activeImage].subtitle}
                >
                  {images[activeImage].subtitle}
                </StyledSubTitle>
              </StyledText>
              <StyledImage
                alt={images[activeImage].title}
                src={images[activeImage].url}
              />
            </motion.div>
          </AnimatePresence>
        </StyledCarousel>
      )}
    </>
  )
}

const StyledArrowsContainer = styled.div`
  display: flex;
  z-index: 10;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  svg {
    color: #fff;
    font-size: 30px;
    @media (min-width: 768px) {
      font-size: 50px;
    }
  }
  @media (min-width: 768px) {
    padding: 100px;
  }
`

const StyledPButton = styled.button`
  cursor: pointer;
  height: max-content;
  background-color: transparent;
  border: none;
`

const StyledNButton = styled.button`
  cursor: pointer;
  height: max-content;
  background-color: transparent;
  border: none;
`

const StyledText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    align-items: unset;
    margin-left: 20%;
    line-height: 1;
    font-size: 2rem;
  }
  @media (min-width: 1280px) {
    font-size: 3rem;
  }
  width: 100%;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  line-height: 1.3;
`

const StyledTitle = styled(motion.h2)`
  margin: 0;
`
const StyledSubTitle = styled(motion.h3)`
  margin: 0;
  font-weight: 400;
  font-family: "Pacifico", cursive;
`

const StyledWave = styled.img`
  position: absolute;
  bottom: 0;
  width: 110%;
`
const StyledImage = styled.img`
  object-fit: cover;
  /* object-position: 70% 50%; */ /* para que se centre la imagen */
  width: 100%;
  height: 100%;
`

const StyledCarousel = styled.div`
  position: relative;
  overflow: hidden;
  height: 70vh;
  max-height: 650px;
  @media (orientation: landscape) {
    height: 90vh;
  }
  > div {
    height: 100%;
  }
`

export default Carousel
