import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";
import Seminario from "../Seminario";
import { toast } from "react-toastify";
import { useUser } from "../../hooks/useUser";
import { eliminarDiacriticosEs } from "../../utils/textConverter";
import CloudOffIcon from "@mui/icons-material/CloudOff";

const Seminarios = () => {
  const [data, setData] = useState(undefined);
  const [handleLoaded] = useOutletContext();
  const { get } = helpHttp();
  const { user } = useUser();
  const [activeSeminars, setActiveSeminars] = useState([]);
  const [previousSeminars, setPreviousSeminars] = useState([]);

  useEffect(() => {
    get("seminarios/getSeminarios")
      .then((data) => {
        setData(data.datos);
      })
      .catch(() => toast.error("Ha ocurrido un error"))
      .finally(() => {
        handleLoaded();
      });
  }, []);

  useEffect(() => {
    get(`usuarios/getProfileById/${user.id}`)
      .then((response) => {
        if (response?.datos?.seminarios) {
          setActiveSeminars(response.datos.seminarios?.map(({ id }) => id));
        }
      })
      .catch(() => {
        toast.error("Ha ocurrido un error");
      });
  }, []);

  useEffect(() => {
    get(`seminarios/getHistoricoSeminarios`).then((response) => {
      console.log(response.datos);
      setPreviousSeminars(response.datos);
    });
  }, []);

  return (
    <div style={{ width: "100%", paddingTop: "15px" }}>
      {data?.length > 0 ? (
        <GridData>
          {data.map((seminario) => {
            return (
              <Seminario
                subscribed={activeSeminars.includes(seminario.id)}
                key={seminario.id}
                seminario={seminario}
              />
            );
          })}
        </GridData>
      ) : (
        <StyledSeminarios>
          <img src="/images/webinars__404.png" alt="Seminarios" />
          <Typography variant="subtitle">
            Lo sentimos, en el momento no hay seminarios activos
          </Typography>
        </StyledSeminarios>
      )}

      <PreviousData>
        <Typography variant="title" fontSize={"25px"} fontWeight={700}>
          Seminarios anteriores
        </Typography>
        <PreviousSeminarsStyled>
          {previousSeminars.map((e, index) => (
            <StyledCard key={e.id} id={eliminarDiacriticosEs(e.nombre, true)}>
              <StyledVideo>
                {e.grabacion ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={e.grabacion}
                    frameBorder="0"
                    loading="lazy"
                    allow="fullscreen; picture-in-picture"
                  />
                ) : (
                  <NoVideo>
                    <CloudOffIcon color="disabled" fontSize="large" />
                    <Typography variant="body1" color={"gray"}>
                      Video no encontrado
                    </Typography>
                  </NoVideo>
                )}
              </StyledVideo>
              <StyledInfo>
                <Typography component={"h3"} variant="title">
                  {e.nombre}
                </Typography>
                <Typography>
                  <span className="info">
                    <b>Fecha: </b>
                    {e.fecha} {e.hora}
                  </span>{" "}
                  <span className="info">
                    <b>Duración: </b> {e.duracion}m
                  </span>
                  <span className="info">
                    <b>Por:</b> {e.por}
                  </span>
                </Typography>
                <Typography>{e.descripcion}</Typography>
              </StyledInfo>
            </StyledCard>
          ))}
        </PreviousSeminarsStyled>
      </PreviousData>
    </div>
  );
};

export default Seminarios;

const PreviousData = styled.div`
  padding-top: 50px;
`;

const PreviousSeminarsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 10px;
  gap: 10px;

  > img {
    width: 100px;
  }
  > span {
    padding: 20px 50px;
  }
`;

const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 268px));
  }
  gap: 30px;
`;
const StyledSeminarios = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  > img {
    width: 100px;
  }
  > span {
    padding: 20px 50px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  @media (min-width: 768px) {
    padding: 30px;
  }
  flex-wrap: wrap;
  width: 100%;
  gap: 10px 20px;
`;

const StyledVideo = styled.div`
  aspect-ratio: 16/9;
  flex: 1;
  flex-basis: 360px;
  @media (min-width: 1100px) {
    max-width: 400px;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex: 2;
  flex-basis: 360px;
  flex-direction: column;
  gap: 10px;
  > h3 {
    font-size: 26px;
  }

  span.info {
    margin-right: 10px;
  }
`;

const NoVideo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
`;
