import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

/* PENDIENTE */

const CategoryNav = ({ activeCategory }) => {
  const { categories } = useSelector((state) => state.category);
  return (
    <StyledNav>
      {categories.slice(0, 3)?.map((category) => (
        <StyledCategory
          to={`${category.nombre}`}
          active={category.id === activeCategory ? "true" : ""}
          key={category.id}
        >
          <StyledCategoryImage src={category.icon} alt={category.nombre} />
          <Typography variant="link">{category.nombre}</Typography>
          {category.id === activeCategory && (
            <>
              <StyledCornerTop src="/images/corner--inset.svg" />
              <StyledCornerBottom src="/images/corner--inset.svg" />
            </>
          )}
        </StyledCategory>
      ))}
    </StyledNav>
  );
};
const StyledNav = styled.nav`
  background-color: #114c97;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100vh - 140px);

  border-radius: 30px;
  padding: 10px;
  gap: 10px;
  @media (min-width: 768px) {
    padding: 80px 0 80px 15px;
    position: sticky;
    gap: 25px;
    top: 120px;
    max-width: 150px;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-device-width: 1000px) and (orientation: landscape) {
    height: 100%;
    position: unset;
  }
`;
const StyledCategory = styled(Link)`
  display: flex;
  text-decoration: none;
  color: floralwhite;
  gap: 10px;
  border-radius: 25px;
  height: 100px;
  width: 100%;
  @media (min-width: 768px) {
    border-radius: 30px 0 0 30px;
  }
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  > span {
    font-size: 0.9rem;
  }
  ${(props) =>
    props.active &&
    css`
      background: white;
      color: #114c97;
    `}
`;
const StyledCategoryImage = styled.img`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;
const StyledCornerTop = styled.img`
  position: absolute;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  top: -35px;
  left: 100px;
`;
const StyledCornerBottom = styled.img`
  position: absolute;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  bottom: -35px;
  left: 100px;
  transform: scaleY(-1);
`;

export default CategoryNav;
